import { BaseFormElement } from '../BaseFormElement';
import { default as Component } from 'vue-class-component';
import WithRender from './custom-radio.html';
import { Inject, Prop } from 'vue-property-decorator';
import Base from '@/mixins/base';
import { Fragment } from 'vue-fragment';

interface IRadioOptions {
    label?: string;
    labelAsHtml?: boolean;
    withTooltip?: boolean;
    tooltip?: string;
    value: string;
}

@WithRender
@Component({
    components: { Fragment },
})
export class CustomRadio extends BaseFormElement {
    @Prop() public options!: IRadioOptions[];
    @Prop() public value!: string;
    @Prop() public labelClass?: string;
    @Prop() public inputClass?: string;

    get inputValue() {
        return this.value;
    }

    set inputValue(value) {
        this.$emit('input', value);
    }

    public disabled(): boolean {
        if (this.$store.state.settings.formSettings[this.getForm]) {
            if (
                this.$store.state.settings.formSettings[
                    this.getForm
                ].disabled.includes(this.id)
            ) {
                this.$emit('disabled', true);
                return true;
            }
        }
        this.$emit('disabled', false);
        return false;
    }
}
