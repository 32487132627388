<fragment>
    <span :key="option.value" v-for="option in options">
        <label :for="getId + '-' + option.value" :class="labelClass"
            ><input
                :id="getId + '-' + option.value"
                type="radio"
                v-model="inputValue"
                :value="option.value"
                name="direct_debit_radio"
                :disabled="disabled()"
                :class="inputClass"
            />
            <p class="mb-0" v-if="option.labelAsHtml" v-html="option.label"></p>
            <p class="mb-0" v-else>
                {{option.label}}
                <i
                    v-if="option.withTooltip"
                    class="fas fa-question-circle text-primary-color font-size-12"
                    v-tooltip.top="option.tooltip"
                ></i>
            </p>
            <i></i>
            <span></span>
        </label>
    </span>
</fragment>
